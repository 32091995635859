import React, { useEffect, useMemo, useState } from 'react';
// libs
import { useTranslation } from 'react-i18next';
import { useStore } from 'context/StoreContext';
// components
import { PageHeader } from 'components';

const ReportingWeb: React.FC = () => {
  const { user } = useStore();
  const { t } = useTranslation('reporting');

  const [hash, setHash] = useState<string | null>(
    '#reporting_web/4/N4IgbglgzgrghgGwgLzgFwgewHYgFwhLYCmAtAMYAWcATmiADTjTxKoY4DKxaG2A5lHzYYCBEwwBbYshzF8IAKJpyAegCqAFQDCjEADMICNMRpC8AbVBoAngAd5BKfKY1i+hQH1Pzvc4AKplgAJuZWIMEwNOhYuAT+AOwAInpQJnb4pACMEvaOhOjEaSAAvgC65UxQdkhoYdZ5Cr5MwRDS2FCxXj5tLiD80SIItBC2Cv6aWQASqZh0+KBuHgTezSC2Dgqt7Z04eq1u5BxxIHBQ5MTYrQKlTEiSo8KiCJUgUEFF+OFLppcXCtJWnBPNVLsE9Po5pJ0At1o0CMF3HBRPQmGBEDB8rc4ZsCNIzlF5CUGIt3L9sP8CG07G4oLsOhCoTC8A1cREkSi9OiEJiFNiNvl8bA3LdSfpyZSQOQ7JJGTRofQWTj8oj9MjjFyMVjicqAcQCSLiWKJflpeQ5QrYQKthyNWitXydda8frhUSykw7BBsCRgkleh1YmFCN7iJ4BpgYHZPBBwR6QF6fcRgpw5oqQICIMDQVdSkA==',
  );

  const iframeUrl = useMemo(() => `https://turnilo.api.astrad.io/?a=${user.entityId}${hash}`, [hash, user.entityId]);

  const handleHashChange = (e: any) => {
    if (e?.data?.topic === 'hashChange') {
      window.location.hash = `#${e?.data?.data}`;
    }
  };

  useEffect(() => {
    if (window.location.hash) {
      setHash(window.location.hash);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', handleHashChange);

    return () => {
      window.removeEventListener('message', handleHashChange);
    };
  }, []);

  return (
    <>
      <PageHeader title={t('Reporting Web')} rightActions={<></>} />

      <iframe id='turnilo-reportingweb' width='100%' height='100%' src={iframeUrl} />
    </>
  );
};

export default ReportingWeb;
