import { useMemo } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useStore } from 'context/StoreContext';

interface ProtectedRouteProps {
  agencyId: number;
}

function AgencyProtectedRoute({ agencyId }: ProtectedRouteProps) {
  const { user, accountDetails } = useStore();

  const hasAccess = useMemo(() => {
    if (user.entityId === agencyId || (user.type === 'account' && accountDetails?.agency.id === agencyId)) {
      return true;
    }

    return false;
  }, [agencyId, accountDetails?.agency.id, user.entityId, user.type]);

  return hasAccess ? <Outlet /> : <Navigate to='/' />;
}

export default AgencyProtectedRoute;
