import React, { lazy } from 'react';
// libs
import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from 'react-router-dom';
import { useStore } from 'context/StoreContext';
import routes from 'routes';
import { AccessRuleAction } from 'services/api/types/access-control';
import { AuthUserType } from 'services/api/types/auth';
import { REPORTING_WEB_AGENCY_ID } from 'services/constants';
import { Loadable, PrivateRoute } from 'components';
import AgencyProtectedRoute from 'components/AgencyProtectedRoute';
import ProtectedRoute from 'components/ProtectedRote';
import MyAccount from 'pages/Admin/Accounts/MyAccount';
import ABMReports from 'pages/Analytics/ABMReports';
import ReportingWeb from 'pages/Analytics/ReportingWeb';
// pages
const SignInPage = Loadable(lazy(() => import('pages/SignIn')));
const SignUpPage = Loadable(lazy(() => import('pages/SignUp')));

const MainLayout = Loadable(lazy(() => import('layouts/MainLayout')));

const CampaignsPage = Loadable(lazy(() => import('pages/Campaigns')));
const CreateCampaignPage = Loadable(lazy(() => import('pages/Campaigns/Create')));
const EditCampaignPage = Loadable(lazy(() => import('pages/Campaigns/Edit')));

const CreateLinePage = Loadable(lazy(() => import('pages/Lines/Create')));
const EditLinePage = Loadable(lazy(() => import('pages/Lines/Edit')));

const OptimizationPage = Loadable(lazy(() => import('pages/Optimization')));

const ActivityAudiencesPage = Loadable(lazy(() => import('pages/Audiences/ActivityAudiences')));
const CreateActivityAudiencePage = Loadable(lazy(() => import('pages/Audiences/ActivityAudiences/Create')));
const EditActivityAudiencePage = Loadable(lazy(() => import('pages/Audiences/ActivityAudiences/Edit')));
const CreateMyAudiencePage = Loadable(lazy(() => import('pages/Audiences/FirstPartyAudiences/Create')));
const EditMyAudiencePage = Loadable(lazy(() => import('pages/Audiences/FirstPartyAudiences/Edit')));
const CreatePrebidAudiencePage = Loadable(lazy(() => import('pages/Audiences/CreatePrebidAudience')));
const MyAudiencesPage = Loadable(lazy(() => import('pages/Audiences/FirstPartyAudiences')));
const ThirdPartyAudiencesPage = Loadable(lazy(() => import('pages/Audiences/ThirdPartyAudiences')));
const ABMAudiencesPage = Loadable(lazy(() => import('pages/Audiences/ABMAudiences')));
const CreateABMAudiencesPage = Loadable(lazy(() => import('pages/Audiences/ABMAudiences/Create')));
const EditABMAudiencesPageEdit = Loadable(lazy(() => import('pages/Audiences/ABMAudiences/Edit')));

const ForecastingPage = Loadable(lazy(() => import('pages/Analytics/Availability')));
const MultidimensionalReportPage = Loadable(lazy(() => import('pages/Analytics/MultidimensionalReport')));
const ScheduledReportsPage = Loadable(lazy(() => import('pages/Analytics/ScheduledReports')));
const CreateScheduledReportPage = Loadable(lazy(() => import('pages/Analytics/ScheduledReports/Create')));
const StandardReportPage = Loadable(lazy(() => import('pages/Analytics/StandardReports')));
const CreateStandardReportPage = Loadable(lazy(() => import('pages/Analytics/StandardReports/Create')));
const DeepDataReportsPage = Loadable(lazy(() => import('pages/Analytics/DeepDataReports')));
const CreateDeepDataReportPage = Loadable(lazy(() => import('pages/Analytics/DeepDataReports/Create')));

const EventsMappingPage = Loadable(lazy(() => import('pages/Tracking/EventsMapping')));
const CreateEventMappingPage = Loadable(lazy(() => import('pages/Tracking/EventsMapping/Create')));
const EditEventMappingPage = Loadable(lazy(() => import('pages/Tracking/EventsMapping/Edit')));
const TrackingPage = Loadable(lazy(() => import('pages/Tracking/Tracking')));
const CreateTrackingPage = Loadable(lazy(() => import('pages/Tracking/Tracking/Create')));
const EditTrackingPage = Loadable(lazy(() => import('pages/Tracking/Tracking/Edit')));
const SkAdCampaignMappingPage = Loadable(lazy(() => import('pages/Tracking/SkAdCampaignMapping')));

const AbTestPage = Loadable(lazy(() => import('pages/Assets/AbTest')));
const EditAbTestPage = Loadable(lazy(() => import('pages/Assets/AbTest/Edit')));
const DealIDsPage = Loadable(lazy(() => import('pages/Assets/DealIDs')));
const EditDealIDsPage = Loadable(lazy(() => import('pages/Assets/DealIDs/Edit')));
const CreativesPage = Loadable(lazy(() => import('pages/Assets/Creatives')));
const CreateCreative = Loadable(lazy(() => import('pages/Assets/Creatives/CreateCreative')));
const EditCreative = Loadable(lazy(() => import('pages/Assets/Creatives/EditCreative')));
const FoldersListPage = Loadable(lazy(() => import('pages/Assets/Folders')));
const EditFolderPage = Loadable(lazy(() => import('pages/Assets/Folders/Edit')));
const CreateFolderPage = Loadable(lazy(() => import('pages/Assets/Folders/Create')));
const GeolocationPage = Loadable(lazy(() => import('pages/Assets/Geolocation')));
const EditZipcodesPage = Loadable(lazy(() => import('pages/Assets/Geolocation/EditZipcodes')));
const EditRegionsPage = Loadable(lazy(() => import('pages/Assets/Geolocation/EditRegions')));
const EditGeolocationPage = Loadable(lazy(() => import('pages/Assets/Geolocation/EditGeolocation')));
const IpRangesPage = Loadable(lazy(() => import('pages/Assets/IpRanges')));
const EditIpRangesPage = Loadable(lazy(() => import('pages/Assets/IpRanges/Edit')));
const InventoriesPage = Loadable(lazy(() => import('pages/Assets/Inventories')));
const EditBundlesPage = Loadable(lazy(() => import('pages/Assets/Inventories/EditBundles')));
const EditInventoriesPage = Loadable(lazy(() => import('pages/Assets/Inventories/EditInventories')));

const AddFundsPage = Loadable(lazy(() => import('pages/Finance/AddFunds')));
const InvoicesPage = Loadable(lazy(() => import('pages/Finance/Invoices')));
const BillingDetailsPage = Loadable(lazy(() => import('pages/Finance/BillingDetails')));
const CreatePaymentMethodPage = Loadable(lazy(() => import('pages/Finance/AddFunds/Create')));
const RequestTopUpPage = Loadable(lazy(() => import('pages/Finance/RequestTopUp')));

const FeatureRequestPage = Loadable(lazy(() => import('pages/FeatureRequest')));

const TicketsPage = Loadable(lazy(() => import('pages/Tickets')));

const UserListPage = Loadable(lazy(() => import('pages/Admin/Users')));
const UserEditPage = Loadable(lazy(() => import('pages/Admin/Users/Edit')));
const UserCreatePage = Loadable(lazy(() => import('pages/Admin/Users/Create')));
const AccountEditPage = Loadable(lazy(() => import('pages/Admin/Accounts/Edit')));
const BidLimiterPage = Loadable(lazy(() => import('pages/Admin/BidLimiter')));

const ProfileNotificationSettingsPage = Loadable(lazy(() => import('pages/Profile/NotificationSettings')));

const AccountListPage = Loadable(lazy(() => import('pages/Admin/Accounts/List')));
const AccountCreatePage = Loadable(lazy(() => import('pages/Admin/Accounts/Create')));
const AgencyEditPage = Loadable(lazy(() => import('pages/Admin/Agencies/Edit')));

const useRouter = () => {
  const { user } = useStore();

  return createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route element={<PrivateRoute />}>
          <Route path={routes.dashboard} element={<MainLayout />}>
            <Route path={routes.dashboard} element={<CampaignsPage />} />

            <Route path={routes.createCampaign} element={<CreateCampaignPage />} />
            <Route path={routes.editCampaign} element={<EditCampaignPage />} />

            <Route path={routes.createLines} element={<CreateLinePage />} />
            <Route path={routes.editLines} element={<EditLinePage />} />

            <Route path={routes.optimization} element={<OptimizationPage />} />

            <Route path={routes.audiences.myAudiences} element={<MyAudiencesPage />} />
            <Route path={routes.audiences.myAudiencesCreate} element={<CreateMyAudiencePage />} />
            <Route path={`${routes.audiences.myAudiences}/:id/edit`} element={<EditMyAudiencePage />} />

            <Route path={routes.audiences.prebidAudienceCreate} element={<CreatePrebidAudiencePage />} />

            <Route path={routes.audiences.activityAudiences} element={<ActivityAudiencesPage />} />
            <Route path={routes.audiences.activityAudienceCreate} element={<CreateActivityAudiencePage />} />
            <Route path={`${routes.audiences.activityAudiences}/:id/edit`} element={<EditActivityAudiencePage />} />
            <Route path={routes.audiences.thirdPartyAudiences} element={<ThirdPartyAudiencesPage />} />
            <Route path={routes.audiences.abmAudiences} element={<ABMAudiencesPage />} />
            <Route path={routes.audiences.abmAudiencesCreate} element={<CreateABMAudiencesPage />} />
            <Route path={`${routes.audiences.abmAudiences}/:id/edit`} element={<EditABMAudiencesPageEdit />} />

            <Route element={<ProtectedRoute accessRuleAction={AccessRuleAction.AnalyticsAvailability} />}>
              <Route path={routes.analytics.availability} element={<ForecastingPage />} />
            </Route>
            <Route element={<ProtectedRoute accessRuleAction={AccessRuleAction.AnalyticsStandardReport} />}>
              <Route path={routes.analytics.standardReports} element={<CreateStandardReportPage />} />
            </Route>

            <Route element={<ProtectedRoute accessRuleAction={AccessRuleAction.AnalyticsDeepDataReport} />}>
              <Route path={routes.analytics.deepDataReports} element={<DeepDataReportsPage />} />
            </Route>
            <Route element={<ProtectedRoute accessRuleAction={AccessRuleAction.AnalyticsDeepDataReport} />}>
              <Route path={`${routes.analytics.deepDataReports}/:id`} element={<CreateDeepDataReportPage />} />
            </Route>
            <Route element={<ProtectedRoute accessRuleAction={AccessRuleAction.AnalyticsDeepDataReport} />}>
              <Route path={`${routes.analytics.deepDataReports}/create`} element={<CreateDeepDataReportPage />} />
            </Route>
            <Route element={<ProtectedRoute accessRuleAction={AccessRuleAction.AnalyticsScheduledReport} />}>
              <Route path={routes.analytics.scheduledReports} element={<ScheduledReportsPage />} />
            </Route>
            <Route element={<ProtectedRoute accessRuleAction={AccessRuleAction.AnalyticsScheduledReport} />}>
              <Route path={routes.analytics.scheduledReportCreate} element={<CreateScheduledReportPage />} />
            </Route>
            <Route element={<ProtectedRoute accessRuleAction={AccessRuleAction.AnalyticsMultidimensionalReport} />}>
              <Route path={routes.analytics.multidimensionalReport} element={<MultidimensionalReportPage />} />
            </Route>
            <Route element={<AgencyProtectedRoute agencyId={REPORTING_WEB_AGENCY_ID} />}>
              <Route path={routes.analytics.reportingWeb} element={<ReportingWeb />} />
            </Route>

            <Route path={routes.analytics.abmReports} element={<ABMReports />} />

            <Route path={routes.tracking.eventsMapping} element={<EventsMappingPage />} />
            <Route path={routes.tracking.eventsMappingCreate} element={<CreateEventMappingPage />} />
            <Route path={`${routes.tracking.eventsMapping}/:id/edit`} element={<EditEventMappingPage />} />

            <Route path={routes.tracking.tracking} element={<TrackingPage />} />
            <Route path={routes.tracking.createTracking} element={<CreateTrackingPage />} />
            <Route path={`${routes.tracking.tracking}/:id/edit`} element={<EditTrackingPage />} />
            <Route path={routes.tracking.skAdCampaignMapping} element={<SkAdCampaignMappingPage />} />

            <Route path={routes.assets.abTest} element={<AbTestPage />} />
            <Route path={routes.assets.editAbTest} element={<EditAbTestPage />} />

            <Route path={routes.assets.deals} element={<DealIDsPage />} />
            <Route path={routes.assets.editDeals} element={<EditDealIDsPage />} />

            <Route path={routes.assets.creatives} element={<CreativesPage />} />
            <Route path={routes.assets.createCreative} element={<CreateCreative />} />
            <Route path={`${routes.assets.creatives}/:id/edit`} element={<EditCreative />} />

            <Route path={routes.assets.folders} element={<FoldersListPage />} />
            <Route path={routes.assets.createFolder} element={<CreateFolderPage />} />
            <Route path={routes.assets.editFolder} element={<EditFolderPage />} />

            <Route path={routes.assets.geolocation} element={<GeolocationPage />} />
            <Route path={routes.assets.editGeolocation} element={<EditGeolocationPage />} />
            <Route path={routes.assets.editZipcodes} element={<EditZipcodesPage />} />
            <Route path={routes.assets.editRegions} element={<EditRegionsPage />} />

            <Route path={routes.assets.ipRanges} element={<IpRangesPage />} />
            <Route path={routes.assets.editIpRanges} element={<EditIpRangesPage />} />

            <Route path={routes.assets.inventories} element={<InventoriesPage />} />
            <Route path={routes.assets.editBundles} element={<EditBundlesPage />} />
            <Route path={routes.assets.editInventories} element={<EditInventoriesPage />} />

            <Route path={routes.admin.users} element={<UserListPage />} />
            <Route path={routes.admin.usersCreate} element={<UserCreatePage />} />
            <Route path={routes.admin.users + '/:id/edit'} element={<UserEditPage />} />
            <Route path={routes.admin.accounts + '/:id/edit'} element={<AccountEditPage />} />
            <Route path={routes.admin.accounts + '/my-account'} element={<MyAccount />} />
            <Route path={routes.admin.bidLimiter} element={<BidLimiterPage />} />

            {user.type === AuthUserType.Agency && (
              <>
                <Route path={routes.admin.accounts} element={<AccountListPage />} />
                <Route path={routes.admin.accountsCreate} element={<AccountCreatePage />} />
                <Route path={routes.admin.agencies + '/:id/edit'} element={<AgencyEditPage />} />
              </>
            )}

            <Route path={routes.finance.addFunds} element={<AddFundsPage />} />
            <Route path={routes.finance.invoices} element={<InvoicesPage />} />
            <Route path={routes.finance.billingDetails} element={<BillingDetailsPage />} />
            <Route path={routes.finance.paymentMethodCreate} element={<CreatePaymentMethodPage />} />
            <Route path={routes.finance.requestTopUp} element={<RequestTopUpPage />} />

            <Route path={routes.featureRequest} element={<FeatureRequestPage />} />

            <Route path={routes.tickets} element={<TicketsPage />} />

            <Route path={routes.profile.notificationSettings} element={<ProfileNotificationSettingsPage />} />
          </Route>
        </Route>

        <Route path={routes.auth.signIn} element={<SignInPage />} />
        <Route path={routes.auth.signUp} element={<SignUpPage />} />

        <Route path='*' element={<Navigate to='/' replace />} />
      </>,
    ),
  );
};

const App = () => {
  const router = useRouter();

  return <RouterProvider router={router} />;
};

export default App;
